<template>
  <Layout>
    <template v-if="this.$route.name == 'add-coupon'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="email" class="row">
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label style="font-weight: 500"
                  >Code <span style="color: red">*</span></label
                >
                <b-form-input
                  id="input-1"
                  placeholder="Enter Code"
                  v-model="form.code"
                  :class="{
                    'is-invalid': submitted && $v.form.code.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.code.required"
                  class="invalid-feedback"
                >
                  Code is required.
                </div>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label style="font-weight: 500"
                  >Type <span style="color: red">*</span></label
                >
                <b-form-select
                  id="input-1"
                  placeholder="Enter Type"
                  v-model="form.type"
                  :options="getCouponType"
                  value-field="value"
                  text-field="text"
                  :class="{
                    'is-invalid': submitted && $v.form.type.$error,
                  }"
                ></b-form-select>
                <div
                  v-if="submitted && !$v.form.type.required"
                  class="invalid-feedback"
                >
                  Type is required.
                </div>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label style="font-weight: 500"
                  >Value <span style="color: red">*</span></label
                >
                <b-form-input
                  id="input-1"
                  placeholder="Enter Value"
                  v-model="form.value"
                  type="number"
                  :class="{
                    'is-invalid': submitted && $v.form.value.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.value.required"
                  class="invalid-feedback"
                >
                  Value is required.
                </div>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label style="font-weight: 500"
                  >Uses <span style="color: red">*</span></label
                >
                <b-form-input
                  id="input-1"
                  placeholder="Enter Uses"
                  v-model="form.uses"
                  type="number"
                  :class="{
                    'is-invalid': submitted && $v.form.uses.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.uses.required"
                  class="invalid-feedback"
                >
                  Uses is required.
                </div>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label style="font-weight: 500"
                  >Valid From <span style="color: red">*</span></label
                >
                <b-form-input
                  id="input-1"
                  placeholder="Valid From"
                  v-model="form.valid_from"
                  type="date"
                  :min="disabledPastDates()"
                  @change="changeValidFrom"
                  :class="{
                    'is-invalid': submitted && $v.form.valid_from.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.valid_from.required"
                  class="invalid-feedback"
                >
                  Valid From is required.
                </div>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label style="font-weight: 500"
                  >Valid Till <span style="color: red">*</span></label
                >
                <b-form-input
                  id="input-1"
                  placeholder="Valid From"
                  v-model="form.valid_till"
                  type="date"
                  :min="form.valid_from || disabledPastDates()"
                  :class="{
                    'is-invalid': submitted && $v.form.valid_till.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.valid_till.required"
                  class="invalid-feedback"
                >
                  Valid Till is required.
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label style="font-weight: 500"
                  >Max Discount Amount <span style="color: red">*</span></label
                >
                <b-form-input
                  id="input-1"
                  placeholder="Enter Discount Amount"
                  v-model="form.discount_amount"
                  type="number"
                  :class="{
                    'is-invalid': submitted && $v.form.discount_amount.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.discount_amount.required"
                  class="invalid-feedback"
                >
                  Discount Amount is required.
                </div>
              </b-form-group>
              <b-form-group class="col-6">
                <label for="multi_event" style="font-weight: 500"
                  >Product <span style="color: red">*</span></label
                >
                <multiselect
                  id="item"
                  :options="getProduct"
                  v-model="form.product"
                  :multiple="false"
                  track-by="id"
                  label="name"
                  placeholder="Type here to search"
                  @input="productChange"
                  :class="{
                    'is-invalid': submitted && $v.form.product.$error,
                  }"
                >
                  <span slot="noOptions"> Type here to search </span>
                </multiselect>
                <div
                  v-if="submitted && !$v.form.product.required"
                  class="invalid-feedback"
                >
                  product is required.
                </div>
              </b-form-group>

              <b-form-group class="col-6">
                <div
                  class="d-flex justify-content-between align-items-center mb-2"
                >
                  <label for="multi_event" style="font-weight: 500"
                    >Coupon Items<span style="color: red">*</span></label
                  >
                  <b-form-checkbox
                    v-model="status"
                    button
                    button-variant="info"
                    size="sm"
                  >
                    <template v-if="status">Unselect All</template>
                    <template v-else>Select All</template>
                  </b-form-checkbox>
                </div>
                <multiselect
                  id="item"
                  :options="getCoupons"
                  v-model="form.multi_coupons"
                  :multiple="true"
                  track-by="id"
                  label="name"
                  placeholder="Type here to search"
                  :class="{
                    'is-invalid': submitted && $v.form.multi_coupons.$error,
                  }"
                >
                  <span slot="noOptions"> Type here to search </span>
                </multiselect>
                <div
                  v-if="submitted && !$v.form.multi_coupons.required"
                  class="invalid-feedback"
                >
                  Coupon Items is required.
                </div>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-12"
              >
                <b-form-checkbox v-model="form.status"
                  >Is active</b-form-checkbox
                >
              </b-form-group>

              <div class="col-12">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-2"
                  @click.prevent="submitData"
                  ><span v-if="this.$route.name == 'add-coupon'"
                    >Save Data</span
                  >
                  <span v-else>Update Data</span>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import couponMixin from "../../../mixins/ModuleJs/coupon";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  mixins: [MixinRequest, couponMixin],
  data() {
    return {
      submitted: false,
      title1: "Add Coupon",
      title2: "Edit Coupon",
      items: [
        {
          text: "Back",
          href: "/coupon",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  validations: {
    form: {
      code: { required },
      type: { required },
      value: { required },
      uses: { required },
      valid_from: { required },
      valid_till: { required },
      discount_amount: { required },
      status: { required },
      product: { required },
      multi_coupons: { required },
    },
  },
};
</script>
